var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('form-radio-basic'), _c('form-radio-custom'), _c('form-radio-color'), _c('form-radio-contextual-states'), _c('form-radio-stack-inline'), _c('form-radio-options-array'), _c('form-radio-field-names')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }