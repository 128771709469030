var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Contextual states"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeStates) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_c('span', [_vm._v("Bootstrap includes validation styles for ")]), _c('code', [_vm._v("valid")]), _c('span', [_vm._v(" and ")]), _c('code', [_vm._v("invalid")]), _c('span', [_vm._v(" states on most form controls.")])]), _c('div', [_c('b-form-radio-group', {
    staticClass: "demo-inline-spacing",
    attrs: {
      "options": _vm.options,
      "state": _vm.state,
      "name": "radio-validation"
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, [_c('b-form-invalid-feedback', {
    attrs: {
      "state": _vm.state
    }
  }, [_vm._v(" Please select one ")]), _c('b-form-valid-feedback', {
    attrs: {
      "state": _vm.state
    }
  }, [_vm._v(" Thank you ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }