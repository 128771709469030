var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Color"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeColor) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_c('span', [_vm._v("To change the color of the radio use the ")]), _c('code', [_vm._v(".custom-control-{value}")]), _c('span', [_vm._v(" for primary, secondary, success, danger, info, warning.")])]), _c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('b-form-radio', {
    staticClass: "custom-control-primary",
    attrs: {
      "name": "some-radio9",
      "value": "e"
    },
    model: {
      value: _vm.Selected,
      callback: function callback($$v) {
        _vm.Selected = $$v;
      },
      expression: "Selected"
    }
  }, [_vm._v(" Primary (Default) ")]), _c('b-form-radio', {
    staticClass: "custom-control-secondary",
    attrs: {
      "name": "some-radio9",
      "value": "ff"
    },
    model: {
      value: _vm.Selected,
      callback: function callback($$v) {
        _vm.Selected = $$v;
      },
      expression: "Selected"
    }
  }, [_vm._v(" Secondary ")]), _c('b-form-radio', {
    staticClass: "custom-control-success",
    attrs: {
      "name": "some-radio9",
      "value": "g"
    },
    model: {
      value: _vm.Selected,
      callback: function callback($$v) {
        _vm.Selected = $$v;
      },
      expression: "Selected"
    }
  }, [_vm._v(" Success ")]), _c('b-form-radio', {
    staticClass: "custom-control-danger",
    attrs: {
      "name": "some-radio9",
      "value": "i"
    },
    model: {
      value: _vm.Selected,
      callback: function callback($$v) {
        _vm.Selected = $$v;
      },
      expression: "Selected"
    }
  }, [_vm._v(" Danger ")]), _c('b-form-radio', {
    staticClass: "custom-control-warning",
    attrs: {
      "name": "some-radio9",
      "value": "h"
    },
    model: {
      value: _vm.Selected,
      callback: function callback($$v) {
        _vm.Selected = $$v;
      },
      expression: "Selected"
    }
  }, [_vm._v(" Warning ")]), _c('b-form-radio', {
    staticClass: "custom-control-info",
    attrs: {
      "name": "some-radio9",
      "value": "j"
    },
    model: {
      value: _vm.Selected,
      callback: function callback($$v) {
        _vm.Selected = $$v;
      },
      expression: "Selected"
    }
  }, [_vm._v(" Info ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }