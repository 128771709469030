var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Custom Radio Buttons"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeCustom) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" For cross browser consistency, "), _c('code', [_vm._v("<b-form-radio-group>")]), _vm._v(" and "), _c('code', [_vm._v("<b-form-radio>")]), _vm._v(" uses Bootstrap's custom radio input to replace the browser default radio input. ")]), _c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "A"
    },
    model: {
      value: _vm.Selected,
      callback: function callback($$v) {
        _vm.Selected = $$v;
      },
      expression: "Selected"
    }
  }, [_vm._v(" Checked ")]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "B"
    },
    model: {
      value: _vm.Selected,
      callback: function callback($$v) {
        _vm.Selected = $$v;
      },
      expression: "Selected"
    }
  }, [_vm._v(" Unchecked ")]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios2",
      "value": "c",
      "disabled": ""
    },
    model: {
      value: _vm.Selected1,
      callback: function callback($$v) {
        _vm.Selected1 = $$v;
      },
      expression: "Selected1"
    }
  }, [_vm._v(" Checked Disabled ")]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios2",
      "value": "d",
      "disabled": ""
    },
    model: {
      value: _vm.Selected1,
      callback: function callback($$v) {
        _vm.Selected1 = $$v;
      },
      expression: "Selected1"
    }
  }, [_vm._v(" Unchecked Disabled ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }