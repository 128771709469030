var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Inline or stacked radios"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeInlineStack) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("By default ")]), _c('code', [_vm._v("<b-form-radio-group>")]), _c('span', [_vm._v(" generates inline radio inputs, while ")]), _c('code', [_vm._v("<b-form-radio>")]), _c('span', [_vm._v(" generates stacked radios. Set the prop ")]), _c('code', [_vm._v("stacked")]), _c('span', [_vm._v(" on ")]), _c('code', [_vm._v("<b-form-radio-group>")]), _c('span', [_vm._v(" to make the radios appear one over the other, or when using radios not in a group, set the ")]), _c('code', [_vm._v("inline")]), _c('span', [_vm._v(" prop on ")]), _c('code', [_vm._v("b-form-radio")]), _c('span', [_vm._v(" to true to render them inline.")])]), _c('div', [_c('b-form-group', {
    attrs: {
      "label": "Inline radios (default)"
    }
  }, [_c('b-form-radio-group', {
    staticClass: "demo-inline-spacing",
    attrs: {
      "options": _vm.options,
      "name": "radio-inline"
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Stacked radios"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "options": _vm.options,
      "name": "radios-stacked",
      "stacked": ""
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1), _c('b-card-text', {
    staticClass: "mt-1 mb-0"
  }, [_vm._v(" Selected: "), _c('strong', [_vm._v(_vm._s(_vm.selected))])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }